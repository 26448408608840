export enum EMetricTypes {
  SALES = 'SALES',
  PUBLISHED_PAID_ARTS = 'PUBLISHED_PAID_ARTS',
  RATING = 'RATINGS',
  REVIEWS = 'REVIEWS',
}

export interface IAchievementProgressDataType {
  metric_type: EMetricTypes;
  count: number;
  current_level: number;
  next_level_count: number;
  is_seen: boolean;
}

export enum ERewardTypes {
  NEW_AUTHOR = 'NEW_AUTHOR',
  FIRST_BOOK = 'FIRST_BOOK',
  FIRST_AUDIOBOOK = 'FIRST_AUDIOBOOK',
  FIRST_DRAFT = 'FIRST_DRAFT',
}

export interface IAchievementRewardsDataType {
  reward_type: ERewardTypes;
  reward_issued_datetime: number;
  is_seen: true;
}

export interface IPrompt {
  id: string;
  title: string;
  description: string;
}

export interface IAchievementDescriptionPageData {
  title: string;
  currentLevel: number;
  description: string;
  scoreCurrent: number;
  scoreMax: number;
  promptsSectionTitle: string;
  propmts: IPrompt[];
}

export enum EGiftTypes {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
}

export interface IAchievementsGiftsType {
  gift_type: EGiftTypes;
  gift_issued_datetime: number;
  is_seen: boolean;
}

export interface IAchievementsGiftPromoType {
  gift_type: EGiftTypes;
  gift_issued_datetime: number;
  is_seen: boolean;
  promo_code: string;
  promo_code_is_used: boolean;
}
