import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IPublisherReadingStatisticsReportResponse,
  IPublisherSalesStatisticsReportResponse,
  TFetchAlgorithm,
} from 'entities/PublisherStatistics/types';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getPublisherGeneralStatisticsThunk } from 'sp-redux/thunks/publisher/statistics/getPublisherGeneralStatisticsThunk';
import { getPublisherReadingStatisticsThunk } from 'sp-redux/thunks/publisher/statistics/getPublisherReadingStatisticsThunk';
import { getPublisherSalesStatisticsThunk } from 'sp-redux/thunks/publisher/statistics/getPublisherSalesStatisticsThunk';
import { getPublisherTotalStatisticsThunk } from 'sp-redux/thunks/publisher/statistics/getPublisherTotalStatisticsThunk';

import { IPublisherStatisticsState } from './types';

const initialState: IPublisherStatisticsState = {
  errors: [],
  reading: {
    isFetching: false,
    data: {
      data: [],
      total: {
        rows: 0,
        total_royalty_sum: 0,
        sale_count: 0,
        sale_royalty_sum: 0,
        prorata_royalty_sum: 0,
        read_royalty_sum: 0,
        read_count: 0,
        prorata_count: 0,
      },
      meta: {
        rightholder_name: '',
      },
    },
  },
  sales: {
    isFetching: false,
    data: {
      data: [],
      total: {
        rows: 0,
        total_royalty_sum: 0,
        sale_count: 0,
        sale_royalty_sum: 0,
        prorata_royalty_sum: 0,
        read_royalty_sum: 0,
        read_count: 0,
        prorata_count: 0,
      },
    },
  },
  general: {
    fetchAlgorithm: 'next-page',
    isFetching: false,
    data: {
      data: [],
      total: {
        royalty_sum: 0,
        total_royalty_sum: 0,
      },
    },
  },
  total: {
    isFetching: false,
    data: {
      royalty_sum: 0,
    },
  },
};

const publisherStatisticsSlice = createSlice({
  name: 'publisherStatistics',
  initialState,
  reducers: {
    resetReadingData: state => {
      state.reading = initialState.reading;
    },
    resetSalesData: state => {
      state.sales = initialState.sales;
    },
    setFetchAlgorithm: (state, action: PayloadAction<TFetchAlgorithm>) => {
      state.general.fetchAlgorithm = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPublisherGeneralStatisticsThunk.pending, state => {
      state.general.isFetching = true;
    });
    builder.addCase(getPublisherGeneralStatisticsThunk.rejected, state => {
      state.general.isFetching = false;
    });
    builder.addCase(
      getPublisherGeneralStatisticsThunk.fulfilled,
      (state, action) => {
        state.general.isFetching = false;
        state.general.data = action.payload.result;
        state.errors = action.payload.errors;
      },
    );
    builder.addCase(getPublisherReadingStatisticsThunk.pending, state => {
      state.reading.isFetching = true;
    });
    builder.addCase(getPublisherReadingStatisticsThunk.rejected, state => {
      state.reading.isFetching = false;
    });
    builder.addCase(
      getPublisherReadingStatisticsThunk.fulfilled,
      (state, action) => {
        state.reading.isFetching = false;
        const currentData = state.reading.data?.data || [];
        state.errors = action.payload.errors;
        const resultData = action.payload.result?.data || [];
        const updatedData = [...currentData, ...resultData];
        if (state.reading.data?.data) {
          state.reading.data.data = updatedData;
          const result = action.payload
            .result as Partial<IPublisherReadingStatisticsReportResponse>;
          state.reading.data.total = {
            ...state.reading.data.total,
            ...result.total,
          };
          const rightHolderName = result.meta?.rightholder_name || '';
          state.reading.data.meta = { rightholder_name: rightHolderName };
        }
      },
    );
    builder.addCase(getPublisherSalesStatisticsThunk.pending, state => {
      state.sales.isFetching = true;
    });
    builder.addCase(getPublisherSalesStatisticsThunk.rejected, state => {
      state.sales.isFetching = false;
    });
    builder.addCase(
      getPublisherSalesStatisticsThunk.fulfilled,
      (state, action) => {
        state.sales.isFetching = false;
        state.errors = action.payload.errors;
        const currentData = state.sales.data?.data || [];
        const resultData = action.payload.result?.data || [];
        const updatedData = [...currentData, ...resultData];
        if (state.sales.data?.data) {
          state.sales.data.data = updatedData;
          const result = action.payload
            .result as Partial<IPublisherSalesStatisticsReportResponse>;
          state.sales.data.total.total_royalty_sum =
            result.total?.total_royalty_sum || 0;
          state.sales.data.total.rows = result.total?.rows || 0;
          const rightHolderName = result.meta?.rightholder_name || '';
          state.sales.data.meta = { rightholder_name: rightHolderName };
        }
      },
    );
    builder.addCase(getPublisherTotalStatisticsThunk.pending, state => {
      state.general.isFetching = true;
    });
    builder.addCase(getPublisherTotalStatisticsThunk.rejected, state => {
      state.general.isFetching = false;
    });
    builder.addCase(
      getPublisherTotalStatisticsThunk.fulfilled,
      (state, action) => {
        state.general.isFetching = false;
        state.errors = action.payload.errors;
        if (state.total.data && !action.payload.errors.length) {
          state.total.data.royalty_sum = action.payload.result.royalty_sum;
        }
      },
    );
    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IPublisherStatisticsState>) => {
        return action.payload.publisherStatistics;
      },
    );
  },
});

export const { resetReadingData, resetSalesData, setFetchAlgorithm } =
  publisherStatisticsSlice.actions;

export const publisherStatisticsReducer = publisherStatisticsSlice.reducer;
