import {
  PUBLICATIONS_PROMPTS,
  RATING_PROMPTS,
  REVIEWS_PROMPTS,
  SALES_PROMPTS,
} from './constants';
import {
  EGiftTypes,
  EMetricTypes,
  IAchievementDescriptionPageData,
  IAchievementProgressDataType,
} from './types';

export const getFilteredProgressItems = (
  items: IAchievementProgressDataType[] | null,
): IAchievementProgressDataType[] => {
  return (
    items?.filter(
      item =>
        item.metric_type !== EMetricTypes.RATING &&
        item.metric_type !== EMetricTypes.REVIEWS,
    ) ?? []
  );
};

export const getTitleByMetricType = (metricType: EMetricTypes): string => {
  switch (metricType) {
    case EMetricTypes.PUBLISHED_PAID_ARTS:
      return 'Публикации';
    case EMetricTypes.RATING:
      return 'Оценки';
    case EMetricTypes.REVIEWS:
      return 'Отзывы';
    case EMetricTypes.SALES:
      return 'Продажи';
    default:
      return '';
  }
};

export const getLinkByMetricType = (metricType: EMetricTypes): string => {
  switch (metricType) {
    case EMetricTypes.PUBLISHED_PAID_ARTS:
      return 'publications';
    case EMetricTypes.RATING:
      return 'rating';
    case EMetricTypes.REVIEWS:
      return 'reviews';
    case EMetricTypes.SALES:
      return 'sales';
    default:
      return '';
  }
};

export const getSlugByMetricType = (metricType: EMetricTypes): string => {
  switch (metricType) {
    case EMetricTypes.PUBLISHED_PAID_ARTS:
      return 'published-paid-arts';
    case EMetricTypes.RATING:
      return 'ratings';
    case EMetricTypes.REVIEWS:
      return 'reviews';
    case EMetricTypes.SALES:
      return 'sales';
    default:
      return '';
  }
};

const getRemainingCount = (
  current: number | undefined,
  max: number | undefined,
): number => {
  return Math.max(0, (max ?? 0) - (current ?? 0));
};

export const getSalesContent = (
  data: IAchievementProgressDataType | null,
): IAchievementDescriptionPageData => ({
  title: getTitleByMetricType(EMetricTypes.SALES),
  currentLevel: data?.current_level ?? 0,
  description: `До следующего уровня осталось ${getRemainingCount(
    data?.count,
    data?.next_level_count,
  )} продаж книг`,
  scoreCurrent: data?.count ?? 0,
  scoreMax: data?.next_level_count ?? 0,
  promptsSectionTitle: 'Как получить больше продаж?',
  propmts: SALES_PROMPTS,
});

export const getReviewsContent = (
  data: IAchievementProgressDataType | null,
): IAchievementDescriptionPageData => ({
  title: getTitleByMetricType(EMetricTypes.REVIEWS),
  currentLevel: data?.current_level ?? 0,
  description: `До следующего уровня осталось ${getRemainingCount(
    data?.count,
    data?.next_level_count,
  )} отзывов`,
  scoreCurrent: data?.count ?? 0,
  scoreMax: data?.next_level_count ?? 0,
  promptsSectionTitle: 'Как получить больше продаж?',
  propmts: REVIEWS_PROMPTS,
});

export const getRatingContent = (
  data: IAchievementProgressDataType | null,
): IAchievementDescriptionPageData => ({
  title: getTitleByMetricType(EMetricTypes.RATING),
  currentLevel: data?.current_level ?? 0,
  description: `До следующего уровня осталось ${getRemainingCount(
    data?.count,
    data?.next_level_count,
  )} оценок 4 и 5 на ваши книги`,
  scoreCurrent: data?.count ?? 0,
  scoreMax: data?.next_level_count ?? 0,
  promptsSectionTitle: 'Как получить больше продаж?',
  propmts: RATING_PROMPTS,
});

export const getPublicationsContent = (
  data: IAchievementProgressDataType | null,
): IAchievementDescriptionPageData => ({
  title: getTitleByMetricType(EMetricTypes.PUBLISHED_PAID_ARTS),
  currentLevel: data?.current_level ?? 0,
  description: `До следующего уровня осталось ${getRemainingCount(
    data?.count,
    data?.next_level_count,
  )} платных публикаций ваших текстовых или аудиокниг`,
  scoreCurrent: data?.count ?? 0,
  scoreMax: data?.next_level_count ?? 0,
  promptsSectionTitle: 'Как получить больше продаж?',
  propmts: PUBLICATIONS_PROMPTS,
});

export const getSubtitleByGiftType = (
  giftType: EGiftTypes | undefined,
): string => {
  switch (giftType) {
    case EGiftTypes.FIRST:
      return 'Скидка 10%';
    case EGiftTypes.SECOND:
      return 'Скидка 20%';
    default:
      return '';
  }
};
