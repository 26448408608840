import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { getStaticPageThunk } from 'sp-redux/thunks/ui/getStaticPageThunk';

import { EUiModalTypes } from './types';
import type { IUIAppTypes } from './types';

const initialState: IUIAppTypes = {
  modalFields: {},
  isOpenModal: false,
  isOpenDrawer: false,
  modalType: EUiModalTypes.none,
  staticPage: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openModal(
      state,
      action: PayloadAction<EUiModalTypes | Record<string, EUiModalTypes>>,
    ) {
      if (typeof action.payload === 'string') {
        state.modalType = action.payload;
      } else if (action.payload.modalType) {
        state.modalType = action.payload.modalType;
        state.modalFields = action.payload;
      } else {
        return state;
      }
      state.isOpenModal = true;
      state.isOpenDrawer = false;
    },
    closeModal(state) {
      state.isOpenModal = false;
      state.modalType = EUiModalTypes.none;
    },
    toggleDrawer(state) {
      state.isOpenDrawer = !state.isOpenDrawer;
    },
    closeDrawer(state) {
      state.isOpenDrawer = false;
    },
    clearStaticPage(state) {
      state.staticPage = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStaticPageThunk.fulfilled, (state, action) => {
      state.staticPage = action.payload.result;
    });
  },
});

export const {
  openModal,
  closeModal,
  toggleDrawer,
  closeDrawer,
  clearStaticPage,
} = uiSlice.actions;

export const uiReducer = uiSlice.reducer;
