import {
  sendGoogleAnalytics,
  sendYandexAnalytics,
} from 'analytics/sendAnalytics';

import { pageUrls } from 'constants/pagesUrls';

import { IReportCardProps } from 'components/SharedComponents/ReportCard/types';

import { createUTMUrl } from 'utils/createUTMUrl';

import type { IDropDownTitles } from '../components/DropDownTitle/types';
import type { IReportDownloadButton } from '../components/ReportDownloadButton';
import { STATISTICS_DATE_RANGE } from '../components/ReportManagement/constants';
import type { ITitles } from './types';
import { EBooksTypes, EStatisticsTableTabs, EStatisticsTypes } from './types';

export const defaultLimit = 10;

export const TODAY = new Date().setUTCHours(0, 0, 0, 0);

const thirtyDaysAgo = Number(STATISTICS_DATE_RANGE.lastThirtyDays());
export const thirtyDaysAgoToUTC = new Date(thirtyDaysAgo).setUTCHours(
  0,
  0,
  0,
  0,
);

export const firstDateOfMonth = Number(STATISTICS_DATE_RANGE.currentMonth());

export const getDropDownData = (tab: EBooksTypes): IDropDownTitles[] => {
  return [
    {
      title: 'Просмотры',
      name: EStatisticsTypes.views,
      isShown: tab !== EBooksTypes.printedBook,
    },
    {
      title: 'Озн. фрагмент',
      name: EStatisticsTypes.trialReads,
      isShown: tab !== EBooksTypes.printedBook,
    },
    {
      title: 'Отложенные',
      name: EStatisticsTypes.likes,
      isShown: tab !== EBooksTypes.printedBook,
    },
    {
      title: 'Прочтения',
      name: EStatisticsTypes.readings,
      isShown: tab !== EBooksTypes.printedBook,
    },
    {
      title: tab === EBooksTypes.printedBook ? 'Покупки' : 'Скачивания',
      name: EStatisticsTypes.downloads,
      isShown: true,
    },
    {
      title: 'Роялти',
      name: EStatisticsTypes.royalty,
      isShown: true,
    },
    {
      title:
        tab === EBooksTypes.audio
          ? 'Глубина прослушивания'
          : 'Глубина прочтения',
      name: EStatisticsTypes.readingDepth,
      isShown: tab !== EBooksTypes.printedBook,
    },
  ];
};

const bannerClickAction = 'statistics_banner_click';

export const infoBlockData = {
  [EStatisticsTypes.views]: {
    iconBanner: 'advertisingStatistics',
    titleBanner: 'Продвигайте вашу книгу среди читателей',
    textBanner:
      'Закажите продвижение, чтобы о вашей книге узнали больше читателей',
    linkBanner: pageUrls.services.promotion.index,
    onClickBanner: (id: number, userUuid: string): void => {
      sendGoogleAnalytics({
        action: bannerClickAction,
      });
      sendYandexAnalytics({
        action: bannerClickAction,
        goalParams: {
          selfpub_user_id: id,
          user_id: userUuid,
        },
      });
    },
  },
  [EStatisticsTypes.downloads]: {
    iconBanner: 'advertisingStatistics',
    titleBanner: 'Увеличьте количество продаж вашей книги',
    textBanner:
      'Закажите продвижение ваших книг, чтобы увеличить количество продаж ',
    linkBanner: pageUrls.services.promotion.index,
    onClickBanner: (id: number, userUuid: string): void => {
      sendGoogleAnalytics({
        action: bannerClickAction,
      });
      sendYandexAnalytics({
        action: bannerClickAction,
        goalParams: {
          selfpub_user_id: id,
          user_id: userUuid,
        },
      });
    },
  },
  [EStatisticsTypes.likes]: {
    iconBanner: 'favoriteBook',
    titleBanner: 'Выделите вашу книгу среди других произведений',
    textBanner:
      'Привлекайте внимание читателей уникальной обложкой от профессионального дизайнера',
    linkBanner: createUTMUrl(pageUrls.services.designOrder, {
      source: 'selfpub',
      medium: 'statistics',
      campaign: 'likes',
    }),
    onClickBanner: undefined,
  },
  [EStatisticsTypes.readings]: {
    iconBanner: 'personalEditorStatistics',
    titleBanner: 'Удержите интерес читателя',
    textBanner:
      'Закажите профессиональную редактуру, чтобы вашу книгу читали с удовольствием',
    linkBanner: pageUrls.services.editing.index,
    onClickBanner: undefined,
  },
  [EStatisticsTypes.royalty]: {
    iconBanner: 'advertisingStatistics',
    titleBanner: 'Зарабатывайте больше!',
    textBanner:
      'Закажите продвижение ваших книг, чтобы увеличить количество продаж ',
    linkBanner: pageUrls.services.promotion.index,
    onClickBanner: (id: number, userUuid: string): void => {
      sendGoogleAnalytics({
        action: bannerClickAction,
      });
      sendYandexAnalytics({
        action: bannerClickAction,
        goalParams: {
          selfpub_user_id: id,
          user_id: userUuid,
        },
      });
    },
  },
  [EStatisticsTypes.trialReads]: {
    iconBanner: 'personalEditorStatistics',
    titleBanner: 'Удержите интерес читателя',
    textBanner:
      'Закажите профессиональную редактуру, чтобы вашу книгу читали с удовольствием',
    linkBanner: pageUrls.services.editing.index,
    onClickBanner: undefined,
  },
  [EStatisticsTypes.readingDepth]: {
    iconBanner: 'advertisingStatistics',
    titleBanner: 'Продвигайте вашу книгу среди читателей',
    textBanner:
      'Закажите продвижение, чтобы о вашей книге узнали больше читателей',
    linkBanner: pageUrls.services.promotion.index,
    onClickBanner: (id: number, userUuid: string): void => {
      sendGoogleAnalytics({
        action: bannerClickAction,
      });
      sendYandexAnalytics({
        action: bannerClickAction,
        goalParams: {
          selfpub_user_id: id,
          user_id: userUuid,
        },
      });
    },
  },
};

export const PROMOTION_OTHER_STATISTICS_PAGES = {
  [EStatisticsTableTabs.royalty]: {
    iconBanner: 'advertisingStatistics',
    titleBanner: 'Зарабатывайте больше!',
    textBanner:
      'Закажите продвижение ваших книг, чтобы увеличить количество продаж',
    linkBanner: pageUrls.services.promotion.index,
    onSendAnalytics: (id: number, userUuid: string): void => {
      const action = 'royalty_banner_click';
      sendGoogleAnalytics({
        action,
      });
      sendYandexAnalytics({
        action,
        goalParams: {
          selfpub_user_id: id,
          user_id: userUuid,
        },
      });
    },
  },
  [EStatisticsTableTabs.reading]: {
    iconBanner: 'advertisingStatistics',
    titleBanner: 'Продвигайте вашу книгу среди читателей',
    textBanner:
      'Закажите продвижение, чтобы о вашей книге узнали больше читателей',
    linkBanner: pageUrls.services.promotion.index,
    onSendAnalytics: (id: number, userUuid: string): void => {
      const action = 'readeractivity_banner_click';
      sendGoogleAnalytics({
        action,
      });
      sendYandexAnalytics({
        action,
        goalParams: {
          selfpub_user_id: id,
          user_id: userUuid,
        },
      });
    },
  },
};

export const NO_CONTENT_BANNER = {
  iconBanner: 'personalEditorStatistics',
  titleBanner: 'Нужна помощь в публикации книги?',
  textBanner:
    'Мы предлагаем профессиональные услуги, которые помогают тысячам писателей оформлять, издавать и продвигать книгу.',
  linkBanner: createUTMUrl(pageUrls.services.index, {
    source: 'selfpub',
    medium: 'statistics',
    campaign: 'empty ',
  }),
};

export const dropDownTitles = (
  type: EStatisticsTypes,
  tab: EBooksTypes,
): string => {
  switch (type) {
    case EStatisticsTypes.views:
      return 'Просмотры';
    case EStatisticsTypes.trialReads:
      return 'Озн. фрагмент';
    case EStatisticsTypes.likes:
      return 'Отложенные';
    case EStatisticsTypes.readings:
      return 'Прочтения';
    case EStatisticsTypes.downloads:
      return 'Скачивания';
    case EStatisticsTypes.royalty:
      return 'Роялти';
    case EStatisticsTypes.readingDepth:
      return tab === EBooksTypes.audio
        ? 'Глубина прослушивания'
        : 'Глубина прочтения';
    default:
      return '';
  }
};

export const dropDownTitlesPrintedBooks: ITitles = {
  sales_count: 'Покупки',
  royalty: 'Роялти',
};

export const REPORT_CARDS: IReportCardProps[] = [
  {
    title: 'Роялти',
    text: 'Изучите количество покупок ваших книг, прочтения по подписке и  итоговую прибыль, которую вы получили',
    buttonText: 'Перейти к отчету',
    buttonLink: pageUrls.statistics.reports.royalty,
    illustrationType: 'royaltyStatistics',
  },
  {
    title: 'Читательская активность',
    text: 'Изучите количество просмотров, прочтений ознакомительных фрагментов, скачиваний и др. показатели ваших книг',
    buttonText: 'Перейти к отчету',
    buttonLink: pageUrls.statistics.reports.reading,
    illustrationType: 'readerActivity',
  },
];

export const bookTabs = [
  { text: 'Текст', active: true, name: EBooksTypes.book },
  { text: 'Аудио', active: false, name: EBooksTypes.audio },
];

export const bookTabsWithPod = [
  { text: 'Текст', active: true, name: EBooksTypes.book },
  { text: 'Аудио', active: false, name: EBooksTypes.audio },
  { text: 'Печатные книги', active: false, name: EBooksTypes.printedBook },
];

export const EMPTY_STATE_TEXTS: Record<EBooksTypes, Record<string, string>> = {
  book: {
    title: 'Опубликуйте вашу первую книгу',
    text: 'Статистика будет доступна после публикации вашей первой электронной книги',
    buttonText: 'Опубликовать книгу',
    buttonLink: `${pageUrls.books.index}ebooks/?state=unpublished`,
  },
  audiobook: {
    title: 'Опубликуйте вашу первую аудиокнигу',
    text: 'Статистика будет доступна после публикации вашей первой  аудиокниги',
    buttonText: 'Опубликовать аудиокнигу',
    buttonLink: `${pageUrls.books.index}audiobooks/?state=unpublished`,
  },
  printed_book: {
    title: '',
    text: '',
    buttonText: '',
    buttonLink: '',
  },
};

export const DOWNLOAD_REPORT_MODAL_TEXTS = {
  send: {
    title: 'Отчёт отправлен',
  },
  error: {
    title: 'Ошибка',
  },
};

export const DOWNLOAD_READING_REPORT_API =
  '/api/statistics/reader-activity-xls/';
export const DOWNLOAD_ROYALTY_REPORT_API = '/api/statistics/royalty-xls/';

export const DOWNLOAD_REPORT_BUTTON: IReportDownloadButton = {
  text: 'Скачать отчет в XLS',
  icon: 'xlsFile',
};

export const PAYMENTS_TEXTS = {
  header: 'Выплаты',
  royaltyDraft: {
    title: 'Роялти черновиков',
    info: 'Здесь будут отображаться ваши роялти за текущий отчетный период',
  },
  royaltyPayoutStatus: {
    title: 'Статус вывода роялти',
    info: 'Здесь будет отображаться статус вывода роялти за закрытый период',
  },
  royaltyReport: {
    title: 'Отчеты за закрытый период',
    info: 'Здесь будут отображаться ваши отчеты по заработанному роялти за закрытый период',
  },
  downloadReportButton: 'Скачать отчет',
  helpTexts: {
    dot: '•',
    moreThousand:
      'Когда общая сумма к выплате достигнет 1000 рублей, вы сможете оставить заявку на получение роялти.',
    moreFiveThousand:
      'Когда общая сумма к выплате достигнет 5000 рублей, вы сможете оставить заявку на получение роялти.',
    noReports:
      'В настоящее время не сформировано ни одного отчета о продажах ваших книг. Отчеты формируются в течение 15 дней после завершения отчетного периода.',
    frozen: {
      text: 'Почему к выплате меньше, чем заработано?',
      linkText: 'Посмотреть роялти черновиков',
    },
    limits:
      'Суммы более 100 тыс. рублей будут разбиты на несколько транзакций.',
  },
};

export const ALERT_INFORM_STATISTICS_DISABLED =
  'Уважаемые правообладатели.\nВ личном кабинете не отображаются роялти за Литрес.Подписку (прората) за текущий месяц.\nМы улучшаемся для вас, и совсем скоро отображение статистики станет ещё удобнее.\nВсе данные по Литрес.Подписке за текущий месяц будут отражены в личных кабинетах после закрытия периода.\nБлагодарим за понимание.';
