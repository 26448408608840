import type { IPublicRuntimeConfig } from 'app.types';
import getConfig from 'next/config';

import { isBrowser } from './environment';

export const getBaseUrl = (): string => {
  const { publicRuntimeConfig } = getConfig();
  const { host, apiUrl }: IPublicRuntimeConfig = publicRuntimeConfig;

  let baseUrl = host;

  if (!isBrowser) {
    baseUrl = apiUrl;
  }

  if (!baseUrl.endsWith('/')) {
    baseUrl = `${baseUrl}/`;
  }

  if (!baseUrl.startsWith('http')) {
    baseUrl = `https://${baseUrl}`;
  }

  return baseUrl;
};
