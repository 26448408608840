import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const getStaticPageThunk = createAsyncThunk(
  'ui/getStaticPage',
  async (slug: string) => {
    const apiPointName = 'pagesStatic';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: { slug },
    });
  },
);
