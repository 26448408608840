import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { EProfileStatuses } from 'entities/Profile/constants';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import type {
  IModerationCommentTemplate,
  IProfileVerificationState,
} from 'sp-redux/slices/verificationProfile/types';
import { getProfileModerationHistoryThunk } from 'sp-redux/thunks/moderation/getProfileModerationHistory';
import { getProfileVerificationThunk } from 'sp-redux/thunks/moderation/getProfileVerification';

const initialState: IProfileVerificationState = {
  id: '',
  profile: {
    id: '',
    full_name: '',
    email: '',
    resident_country: '',
    document_type: '',
    serial_number: '',
    whom_issued: '',
    when_issued: '',
    birthday: '',
    document_scan: '',
    photo_with_document: '',
    additional_scan: '',
  },
  moderation_comments: [],
  status: EProfileStatuses.unverified,
  moderationProfileHistory: [],
};

const VerificationProfileSlice = createSlice({
  name: 'verificationProfile',
  initialState,
  reducers: {
    setComment: (state, action: PayloadAction<IModerationCommentTemplate>) => {
      const existingComentIdx = state.moderation_comments.findIndex(
        e => e.id == action.payload.id,
      );
      if (existingComentIdx != -1) {
        state.moderation_comments[existingComentIdx] = action.payload;
      } else {
        state.moderation_comments.push(action.payload);
      }
    },
    deleteComment: (
      state,
      action: PayloadAction<IModerationCommentTemplate>,
    ) => {
      state.moderation_comments = state.moderation_comments.filter(
        obj => obj.id !== action.payload.id,
      );
    },
  },

  extraReducers: builder => {
    builder.addCase(getProfileVerificationThunk.fulfilled, (state, action) => {
      state.profile = action.payload.result;
      state.id = action.payload.id;
    });

    builder.addCase(
      getProfileModerationHistoryThunk.fulfilled,
      (state, action) => {
        state.moderationProfileHistory = action.payload.result;
      },
    );

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IProfileVerificationState>) => {
        return action.payload.verificationProfile;
      },
    );
  },
});

export const { setComment, deleteComment } = VerificationProfileSlice.actions;
export const verificationProfileReducer = VerificationProfileSlice.reducer;
