import type { IBookMetaStateType } from './types';
import { EPublicationState } from './types';

export const initialState: IBookMetaStateType = {
  isFetched: false,
  isFetching: true,
  meta: {
    id: 0,
    title: '',
    annotation: '',
    tags: [],
    genres: [],
    age: null,
    obscene_language: null,
    authors_sheets: null,
    update_period: null,
    date_written: null,
    completion_date: null,
    employees: [],
    with_printed: false,
    file: null,
    owner: null,
    price: null,
    can_use_draft_license: false,
    can_edit: false,
    amnesty_draft_date: null,
    edition_block_reason: '',
    is_published: false,
    uuid: '',
    days_for_print_publication: 0,
    characters_number: 0,
    processing_status: EPublicationState.unpublishedChanges,
    completion_date_max: '',
    completion_date_min: '',
    file_content_size: 0,
    download_date: '',
    is_draft_book: false,
    is_chapter_draft: null,
    lgbt_mark: false,
    isLgbtRulesAccepted: false,
    litres_art_id: 0,
    rating: {
      rated_avg: 0,
      rated_total_count: 0,
    },
    create_audiobook: null,
    audiobook_license: null,
    audiobook_gender_voice: null,
    audiobook_glossary: null,
    is_overdue: false, // черновик просрочен на 0-59 дней
    is_overdue_and_locked: false, // черновик просрочен на 60 дней
    annotation_ai_generation_count: 0,
  },
  oldMeta: {},
  moderationBookMeta: {},
  bookComments: {
    activeComments: [],
  },
  employees: [],
  errors: null,
  cover: {},
  isPublicationSubmitted: false,
  isCoverChanged: false,
  isCoverLoading: false,
  isChaptersCountChanged: false,
  moderationBookHistory: [],
  hasLgbtConflicts: false,
  chapters: [],
  discounts: {
    info: {},
    archived: {
      result: [],
      pagination: {
        count: 0,
        next_page: '',
        previous_page: '',
      },
    },
    active: {
      result: [],
      pagination: {
        count: 0,
        next_page: '',
        previous_page: '',
      },
    },
    created: {
      result: [],
      pagination: {
        count: 0,
        next_page: '',
        previous_page: '',
      },
    },
    errors: [],
  },
  reviews: {
    status: 0,
    errors: [],
    payload: {
      pagination: {
        next_page: null,
        previous_page: null,
      },
      data: [],
    },
    newReview: [],
  },
  moderationSnapshots: null,
};
