import { createAsyncThunk } from '@reduxjs/toolkit';
import { EGiftTypes } from 'modules/achievements/types';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetAchievementsGiftPromoThunkProps {
  giftType: EGiftTypes;
  context?: GetServerSidePropsContext;
}

export const getAchievementsGiftPromoThunk = createAsyncThunk(
  'achievements/getAchievementsGiftPromo',
  async ({ giftType, context }: IGetAchievementsGiftPromoThunkProps) => {
    const apiPointName = 'getAchievementsGiftPromo';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: {
        giftType,
      },
      context,
    });
  },
);
