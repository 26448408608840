import { createAsyncThunk } from '@reduxjs/toolkit';

import { setLitresAccountLinkBroken } from 'sp-redux/slices/userInfo';

import { doFetch } from 'utils/doFetch';

// Запрос на авторизацию в реферальном кабинете Литрес. В случае успеха откроется новое окно.
export const requestAuthInReferralCabinetOfLitresThunk = createAsyncThunk(
  'userInfo/getAccountData',

  async () => {
    const apiPointName = 'reqAuthInReferralCabinetOfLitres';

    try {
      const { result, errors } = await doFetch<typeof apiPointName>({
        apiPointName,
      });

      if (errors.length > 0) {
        setLitresAccountLinkBroken();

        return;
      }

      if (result.url) {
        window.open(result.url, '_blank');
      }
    } catch (e) {
      setLitresAccountLinkBroken();
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
);
