import { IStaticPageType } from 'components/complex/pages/home/StaticInfo/types';

export enum EUiModalTypes {
  none = '',
  signIn = 'SignIn',
  signUp = 'SignUp',
  restorePassword = 'RestorePassword',
  dialogINN = 'DialogINN',
  signSpecialOffer = 'SignSpecialOffer',
  authorOffer = 'AuthorOffer',
  addDiscount = 'AddDiscount',
}

export interface IUIAppTypes {
  isOpenModal: boolean;
  isOpenDrawer: boolean;
  modalType: EUiModalTypes;
  modalFields: Record<string, EUiModalTypes>;
  staticPage: IStaticPageType | null;
}

// TODO: везде надо заменить использование этой телеги на ECurrentTab
export enum ETabType {
  meta = 'meta',
  cover = 'cover',
  license = 'license',
  profile = 'profile',
  overview = 'overview',
}
