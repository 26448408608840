import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getAudioRoyaltyStatisticsThunk } from 'sp-redux/thunks/statistics/getAudioRoyaltyStatisticsThunk';
import { getAudioRoyaltySumsThunk } from 'sp-redux/thunks/statistics/getAudioRoyaltySumsThunk';
import { getBookRoyaltyStatisticsThunk } from 'sp-redux/thunks/statistics/getBookRoyaltyStatisticsThunk';
import { getBookRoyaltySumsThunk } from 'sp-redux/thunks/statistics/getBookRoyaltySumsThunk';
import { getRawSumsThunk } from 'sp-redux/thunks/statistics/getRawSumsThunk';
import { getReadingStatisticsThunk } from 'sp-redux/thunks/statistics/getReadingStatisticsThunk';
import { getRoyaltyBannerThunk } from 'sp-redux/thunks/statistics/getRoyaltyBannerThunk';
import { getRoyaltyDraftsThunk } from 'sp-redux/thunks/statistics/getRoyaltyDraftsThunk';
import { getRoyaltyOverallThunk } from 'sp-redux/thunks/statistics/getRoyaltyOverallThunk';
import { getRoyaltyPayoutThunk } from 'sp-redux/thunks/statistics/getRoyaltyPayoutThunk';
import { getRoyaltyReportsThunk } from 'sp-redux/thunks/statistics/getRoyaltyReportsThunk';
import { getStatisticsAccessibilityThunk } from 'sp-redux/thunks/statistics/getStatisticsAccessibilityThunk';
import { getStatisticsChartThunk } from 'sp-redux/thunks/statistics/getStatisticsChartThunk';

import type { IStatisticsState } from './types';

const initialState: IStatisticsState = {
  isFetching: false,
  isFetchingSums: false,
  chartStatistics: {
    data: {
      dates: [],
      sales_count: [],
      sales_count_sum: 0,
      hits_count: [],
      hits_count_sum: 0,
      royalty: [],
      royalty_sum: 0,
      likes_count: [],
      likes_count_sum: 0,
      art_card_views_count: [],
      art_card_views_count_sum: 0,
      trial_reads_count: [],
      trial_reads_count_sum: 0,
      reads_avg_percent: [],
      reads_avg_percent_sum: 0,
    },
    errors: [],
  },
  readingStatistics: {
    pagination: {
      count: 0,
      previous_page: '',
      next_page: '',
    },
    result: [],
    errors: [],
  },
  royaltyInfo: {
    with_current_period_royalty: '',
    paid_royalty: '',
    locked_part: '',
    is_payout_enabled: false,
  },
  audioRoyaltyStatistics: {
    results: [],
    pagination: {
      count: 0,
      next_page: '',
      previous_page: '',
    },
    sums: {
      online_library_amount: '',
      online_library_hits: 0,
      royalty: '',
      sales_count: 0,
      sales_sum: '',
    },
    errors: [],
  },
  bookRoyaltyStatistics: {
    results: [],
    pagination: {
      count: 0,
      next_page: '',
      previous_page: '',
    },
    sums: {
      online_library_amount: '',
      online_library_hits: 0,
      royalty: '',
      sales_count: 0,
      sales_sum: '',
    },
    errors: [],
  },
  readingStatisticsSums: {
    sales_count: 0,
    hits_count: 0,
    royalty: 0,
    likes_count: 0,
    art_card_views_count: 0,
    trial_reads_count: 0,
    reviews_count: 0,
    reads_avg_percent: 0,
  },
  isStatisticsForbidden: false,
  royaltyReports: [
    {
      report: '',
      period: '',
    },
  ],
  royaltyDrafts: [
    {
      id: 0,
      cover_url: '',
      title: '',
      progress: 0,
      earned: '',
      frozen: '',
      comments: {
        type: '',
        message: '',
      },
    },
  ],
  royaltyPayout: [
    {
      id: 0,
      amount: '',
      status: '',
      datetime: 0,
    },
  ],
  royaltyBanner: {
    type: '',
    header: '',
    text: '',
  },
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    clearRoyaltyStatistics: state => {
      state.audioRoyaltyStatistics.results = [];
      state.bookRoyaltyStatistics.results = [];
    },
    clearReadingStatistics: state => {
      state.readingStatistics.result = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getStatisticsChartThunk.pending, state => {
      state.isFetching = true;
    });
    builder.addCase(getStatisticsChartThunk.fulfilled, (state, action) => {
      state.isFetching = false;
      if (Object.keys(action.payload.result).length > 0) {
        state.chartStatistics.data = action.payload.result;
      }
      state.chartStatistics.errors = action.payload.errors;
    });
    builder.addCase(getReadingStatisticsThunk.fulfilled, (state, action) => {
      if (action.payload.result.length > 0) {
        state.readingStatistics.result = [
          ...state.readingStatistics.result,
          ...action.payload.result,
        ];
      }

      if (action.payload.pagination) {
        state.readingStatistics.pagination = action.payload.pagination;
      }

      state.readingStatistics.errors = action.payload.errors;
    });
    builder.addCase(getRoyaltyOverallThunk.fulfilled, (state, action) => {
      if (Object.keys(action.payload.result).length > 0) {
        state.royaltyInfo = action.payload.result;
      }
    });
    builder.addCase(
      getStatisticsAccessibilityThunk.fulfilled,
      (state, action) => {
        if (Object.keys(action.payload.result).length > 0) {
          state.isStatisticsForbidden = action.payload.result.forbidden;
        }
      },
    );

    builder.addCase(
      getAudioRoyaltyStatisticsThunk.fulfilled,
      (state, action) => {
        if (action.payload.result.length > 0) {
          state.audioRoyaltyStatistics.results = [
            ...state.audioRoyaltyStatistics.results,
            ...action.payload.result,
          ];
        }
        if (action.payload.pagination) {
          state.audioRoyaltyStatistics.pagination = action.payload.pagination;
        }
        state.audioRoyaltyStatistics.errors = action.payload.errors;
      },
    );

    builder.addCase(
      getBookRoyaltyStatisticsThunk.fulfilled,
      (state, action) => {
        if (action.payload.result.length > 0) {
          state.bookRoyaltyStatistics.results = [
            ...state.bookRoyaltyStatistics.results,
            ...action.payload.result,
          ];
        }
        if (action.payload.pagination) {
          state.bookRoyaltyStatistics.pagination = action.payload.pagination;
        }

        state.bookRoyaltyStatistics.errors = action.payload.errors;
      },
    );

    builder.addCase(getBookRoyaltySumsThunk.fulfilled, (state, action) => {
      if (action.payload.result && Object.keys(action.payload.result).length) {
        state.bookRoyaltyStatistics.sums = action.payload.result;
      }
    });

    builder.addCase(getAudioRoyaltySumsThunk.fulfilled, (state, action) => {
      if (action.payload.result && Object.keys(action.payload.result).length) {
        state.audioRoyaltyStatistics.sums = action.payload.result;
      }
    });

    builder.addCase(getRawSumsThunk.pending, state => {
      state.isFetchingSums = true;
    });

    builder.addCase(getRawSumsThunk.fulfilled, (state, action) => {
      state.isFetchingSums = false;
      if (action.payload.result && Object.keys(action.payload.result).length) {
        state.readingStatisticsSums = action.payload.result;
      }
    });

    builder.addCase(getRoyaltyBannerThunk.fulfilled, (state, action) => {
      state.royaltyBanner = action.payload.result;
    });
    builder.addCase(getRoyaltyReportsThunk.fulfilled, (state, action) => {
      state.royaltyReports = action.payload.result;
    });

    builder.addCase(getRoyaltyDraftsThunk.fulfilled, (state, action) => {
      state.royaltyDrafts = action.payload.result;
    });

    builder.addCase(getRoyaltyPayoutThunk.fulfilled, (state, action) => {
      state.royaltyPayout = action.payload.result;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IStatisticsState>) => {
        return action.payload.statistics;
      },
    );
  },
});

export const { clearReadingStatistics, clearRoyaltyStatistics } =
  statisticsSlice.actions;

export const statisticsReducer = statisticsSlice.reducer;
